document.addEventListener('DOMContentLoaded', function () {
    const ipadImage = document.getElementById('ipad-image');
    let rotationApplied = false;

    const maxRotation = 20;
    const minVisibility = 0.4;
    const maxVisibility = 1;
    const maxFade = 1;

    // Function to update the rotation and fade based on scrolling
    function updateRotationAndFade(scrollTop, containerHeight) {
        const rect = ipadImage.getBoundingClientRect();
        const imageHeight = rect.height;
        const visibleTop = Math.max(0, rect.top);
        const visibleBottom = Math.min(containerHeight, rect.bottom);
        const visibleHeight = visibleBottom - visibleTop;
        const visiblePercent = visibleHeight / imageHeight;
        let rotationDegrees = 0;
        let fadeFactor = maxFade;

        // Initial rotation when page load or scrolling to top
        if (!rotationApplied && scrollTop === 0) {
            ipadImage.style.transform = `rotateX(${maxRotation}deg)`;
            applyMask(fadeFactor);
            rotationApplied = true;
        }

        // Gradually straighten the image as user scrolls
        if (scrollTop > 0 && visiblePercent >= minVisibility && visiblePercent <= maxVisibility && visibleTop > 0) {
            const rotationRange = maxVisibility - minVisibility;
            const rotationFactor = (visiblePercent - minVisibility) / rotationRange;
            rotationDegrees = (1 - rotationFactor) * maxRotation;

            fadeFactor = 1 - ((rotationDegrees / maxRotation)) * maxFade;
            fadeFactor = Math.max(0, fadeFactor);
            fadeFactor = Math.min(1, fadeFactor);

            ipadImage.style.transform = `rotateX(${rotationDegrees}deg)`;
            applyMask(fadeFactor);

            rotationApplied = false;
        }

        // When scrolling down past max visibility
        if (scrollTop > 0 && visiblePercent >= maxVisibility) {
            ipadImage.style.transform = 'rotateX(0deg)';
            applyMask(1);
            rotationApplied = false;
        }

        // When scrolling back to the top
        if (scrollTop === 0) {
            ipadImage.style.transform = `rotateX(${maxRotation}deg)`;
            applyMask(1 - maxFade);
            rotationApplied = true;
        }
    }

    // Function to apply mask based on fade factor
    function applyMask(fadeFactor) {
        ipadImage.style.maskImage = `linear-gradient(to top, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, ${fadeFactor}) 100%)`;
    }

    // Smooth Scrollbar scroll event listener
    const scrollbar = window.Scrollbar.init(document.querySelector('#smooth-scroll-container'), {
        damping: 0.1,
        alwaysShowTracks: true
    });

    scrollbar.addListener(function (status) {
        const scrollTop = status.offset.y;
        const containerHeight = window.innerHeight || document.documentElement.clientHeight;
        updateRotationAndFade(scrollTop, containerHeight);
    });

    // Apply rotation and fade initially on page load
    window.addEventListener('load', function () {
        ipadImage.style.transform = `rotateX(${maxRotation}deg)`;
        applyMask(1 - maxFade);
        rotationApplied = true;
    });
});