document.addEventListener('DOMContentLoaded', function () {
    // Initialize Smooth Scrollbar
    const scrollContainer = document.querySelector('#smooth-scroll-container');
    let scrollbar;

    if (scrollContainer) {
        // Initialize Smooth Scrollbar for the container
        scrollbar = Scrollbar.init(scrollContainer, {
            damping: 0.1, // Smoothness of scroll (0-1)
            alwaysShowTracks: true // Keep scrollbar track always visible
        });
    }

    // Function to scroll to anchor targets using Smooth Scrollbar
    function smoothScrollToAnchor(targetId) {
        if (scrollbar) {
            const targetElement = document.querySelector(targetId);
            if (targetElement) {
                const scrollPosition = scrollbar.offset.y + targetElement.getBoundingClientRect().top;
                scrollbar.scrollTo(0, scrollPosition, 800); // Adjust 800ms to your preferred scroll speed
            }
        }
    }

    // Add click event listener to all anchor links
    const anchorLinks = document.querySelectorAll('a[href^="#"]');
    anchorLinks.forEach(link => {
        link.addEventListener('click', function (event) {
            event.preventDefault(); // Prevent default anchor click behavior

            const targetId = this.getAttribute('href');
            smoothScrollToAnchor(targetId); // Scroll to the target section smoothly
        });
    });

    // Dropdown menu functionality
    const ddMenus = document.querySelectorAll('.dd-menu');
    ddMenus.forEach(ddMenu => {
        const ddMenuPanel = ddMenu.querySelector('.dd-menu-panel');

        ddMenu.addEventListener('mouseenter', function() {
            ddMenu.classList.add('rotated');
            ddMenuPanel.classList.add('show');
        });

        ddMenuPanel.addEventListener('mouseleave', function() {
            ddMenu.classList.remove('rotated');
            ddMenuPanel.classList.remove('show');
        });
    });

    // Function to update opacity based on scroll position with a dynamic threshold
    function updateItemVisibility(item) {
        const rect = item.getBoundingClientRect();
        const windowHeight = window.innerHeight || document.documentElement.clientHeight;

        const visibleTop = Math.max(0, rect.top);
        const visibleBottom = Math.min(windowHeight, rect.bottom);
        const visibleHeight = visibleBottom - visibleTop;
        const visiblePercent = visibleHeight / rect.height;

        const visibilityThreshold = 0.3;
        const maxVisibleThreshold = 0.8;

        let opacity;
        if (visiblePercent < visibilityThreshold) {
            opacity = 0;
        } else if (visiblePercent >= visibilityThreshold && visiblePercent <= maxVisibleThreshold) {
            opacity = (visiblePercent - visibilityThreshold) / (maxVisibleThreshold - visibilityThreshold);
        } else {
            opacity = 1;
        }

        item.style.opacity = opacity;
    }

    // Scrollbar scroll listener to update opacity based on scroll
    if (scrollbar) {
        scrollbar.addListener(() => {
            document.querySelectorAll('.feature-item, .benefit-item').forEach(item => {
                updateItemVisibility(item);
            });
        });
    }

    // Initial update for all items on page load
    window.addEventListener('load', () => {
        document.querySelectorAll('.feature-item, .benefit-item').forEach(item => {
            updateItemVisibility(item);
        });
    });

    // How it works - click event listener for steps
    const items = document.querySelectorAll('.how-it-works-list div');
    const contentBlocks = document.querySelectorAll('.how-it-works-content .content');

    items.forEach(item => {
        item.addEventListener('click', function () {
            items.forEach(i => i.classList.remove('active'));
            contentBlocks.forEach(content => content.classList.remove('active'));

            item.classList.add('active');
            const target = item.getAttribute('data-target');
            document.getElementById(target).classList.add('active');
        });
    });

    // Pagers - previous and next button navigation for steps
    const steps = document.querySelectorAll('.content');

    function updateActivePanel(currentIndex, direction) {
        const nextIndex = currentIndex + direction;

        if (nextIndex >= 0 && nextIndex < steps.length) {
            steps[currentIndex].classList.remove('active');
            steps[nextIndex].classList.add('active');
        }
    }

    steps.forEach((step, index) => {
        const previousBtn = step.querySelector('.previous');
        const nextBtn = step.querySelector('.next');

        if (previousBtn && !previousBtn.classList.contains('disabled')) {
            previousBtn.addEventListener('click', function () {
                updateActivePanel(index, -1);
            });
        }

        if (nextBtn && !nextBtn.classList.contains('disabled')) {
            nextBtn.addEventListener('click', function () {
                updateActivePanel(index, 1);
            });
        }
    });

    if (typeof window !== "undefined") {


        function updateIsMobile() {
            let isSmallDevice = window.innerWidth <= 650;
            let isMediumDevice = window.innerWidth > 650 && window.innerWidth <= 1180;
            let isLargeDevice = window.innerWidth > 1180;

            // document.querySelectorAll('.mobile-only').forEach(el => {
            //     el.style.display = isMobile ? 'block' : 'none';
            // });

            // document.querySelectorAll('.desktop-only').forEach(el => {
            //     el.style.display = isMobile ? 'none' : 'block';
            // });
        }

        updateIsMobile();
        window.addEventListener('resize', updateIsMobile);
    }
});